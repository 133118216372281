/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// Importuj style globalne
import './src/styles/global.scss'

export const onClientEntry = () => {
  window.onload = () => {
    console.log("Strona załadowana")
  }
}

export const onRouteUpdate = ({ location }) => {
  console.log("Zmiana strony na:", location.pathname)
}
