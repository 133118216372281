module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.totalbyggarna.se","trailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Totalbyggarna","short_name":"Totalbyggarna","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/favicon/favicon-96x96.png","icons":[{"src":"src/images/favicon/favicon.ico","sizes":"16x16","type":"image/x-icon"},{"src":"src/images/favicon/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/favicon/web-app-manifest-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon/web-app-manifest-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/images/favicon/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"cache_busting_mode":"none","include_favicon":true,"legacy":true,"theme_color_in_head":true,"crossOrigin":"use-credentials","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
